export const CREATE_KNOWLEDGE_BASE = "/knowledge-base/create"
export const GET_KNOWLEDGE_BASE_LIST = "/knowledge-base/getAll"
export const UPDATE_KNOWLEDGE_BASE = "/knowledge-base/edit"
export const DELETE_KNOWLEDGE_BASE = "/knowledge-base/delete"
export const DOWNLOAD_KNOWLEDGE_BASE_DATA = "/knowledge-base/getFileData"
export const TRAIN_KNOWLEDGE_BASE = "/training/train"
export const EDIT_KNOWLEDGE_BASE = "/knowledge-base/update"
export const CRAWL_KNOWLEDGE_BASE = "/training/crawl-urls"
export const GET_KNOWLEDGE_BASE = "/knowledge-base/get"
export const ADD_DOCUMENT_KNOWLEDGE_BASE = "/knowledge-base/addDocuments"
export const GET_DOCUMENT_KNOWLEDGE_BASE = "/knowledge-base/getDocuments"
export const TRAINING_STATUS = "/training/training-status"
export const CRAWL_STATUS = "/training/status-crawl-urls"
export const DELETE_DOCUMENT = "/knowledge-base/deleteDocument"
export const DELETE_FETCHED_URLS = "/training/delete-fetched-urls"
