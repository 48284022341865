const initialState = {
  knowledgeBaseList: [],
  connectedUrls:[],
  notConnectedUrls:[],
  singleKnowledgeBase: {}, 
  loading: false,
  loadError: false,
  documentUrls: [],
  deleteDocumentLoading: false,
  deleteDocumentError: null,
  singleKnowledgeBaseLoading: false,
  singleKnowledgeBaseError: false, 
  trainingLoading: false, 
  trainingError: null, 
  trainingResponse: null,
  crawlLoading: false,
  crawlError: null,
  crawlStatus: null,
  deleteUrlsLoading: false,
  deleteUrlsError: null,
};

export default initialState;
