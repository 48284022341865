import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, Divider, Tooltip } from "@mui/material";
import GeneralSettings from "./GeneralSettings";
import SpeechSettings from "./SpeechSettings";
import CallSettings from "./CallSettings";
import * as _ from "lodash";
import AssistantFunctions from "./AssistantFunctions";
import { useDispatch, useSelector } from "react-redux";
import { updateAssistantsChangedData } from "../../data/store/assistantsSlice";
import PhoneIcon from "@mui/icons-material/Phone";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import KnowledgeBaseAssistant from "./KnowledgeBase";
import { getKnowledgeBaseList } from "../../data/store/knowledgeBasesSlice";
// Individual Accordion Component
function CustomAccordion({ panel, expanded, handleChange, summary, content }) {
  return (
    <Accordion
      sx={{
        marginTop: "5px",
        boxShadow: "none",
        "&:first-of-type": {
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        },
        marginBottom: "10px",
        borderRadius: "10px",
        "&::before": { display: "none" }, // Remove the ::before pseudo-element
      }}
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panel}-content`}
        id={`${panel}-header`}
        sx={{
          minHeight: "40px", // Adjust the height
          "& .MuiAccordionSummary-content": {
            margin: "0", // Remove default margin
            alignItems: "center", // Center the content
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            width: "100%",
            fontWeight: "bold",
            textAlign: "start", // Center the text
          }}
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {content} {/* Render the custom content */}
      </AccordionDetails>
    </Accordion>
  );
}

// Main Component
export default function AssistantRightComponent({
  setCallAllActionsApi,
  handleSaveChanges,
  handleFetchAllActionData,
  handleTalkToAgentClick,
}) {
  const dispatch = useDispatch();
  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const assistantUpdatedInfo = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [assistant, setAssistant] = useState({
    companyName: "",
    name: "",
  });

  useEffect(() => {
    if (!_.isEmpty(assistantUpdatedInfo)) {
      setSaveLoading(true);
    } else setSaveLoading(false);
  }, [assistantUpdatedInfo]);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFormChange = (updatedValues) => {
    setAssistant((prevAssistant) => ({
      ...prevAssistant,
      ...updatedValues,
    }));
  };

  const handleChangeAssistantChangedData = (data) => {
    dispatch(
      updateAssistantsChangedData({
        key: "assistantUpdatedInfo",
        data,
      })
    );
  };

  return (
    <>
      <Tooltip
        placement="top"
        arrow
        title={
          !assistantInitialData.script?.trim() ||
          assistantInitialData.script === "<p><br></p>"
            ? "Assistant script is required to test"
            : !assistantInitialData.goal?.trim() ||
              assistantInitialData.goal === "<p><br></p>"
            ? "Assistant goal is required to test"
            : !assistantInitialData.callOpeningMessage?.trim() ||
              assistantInitialData.callOpeningMessage === "<p><br></p>"
            ? "Call opening message is required to test"
            : ""
        }
      >
        <span>
          <LoadingButton
            fullWidth
            size="small"
            variant="contained"
            onClick={handleTalkToAgentClick}
            disabled={
              !assistantInitialData?.script?.trim() ||
              assistantInitialData.script === "<p><br></p>" || // Check for empty or "<p><br></p>"
              !assistantInitialData?.goal?.trim() ||
              assistantInitialData.goal === "<p><br></p>" || // Check for empty or "<p><br></p>"
              !assistantInitialData?.callOpeningMessage?.trim() ||
              assistantInitialData.callOpeningMessage === "<p><br></p>" // Check for empty or "<p><br></p>"
            }
            loading={saveLoading}
            loadingIndicator="Saving..."
            sx={{
              marginBottom: "20px",
              borderRadius: "10px",
              color: "secondary",
              textTransform: "none",
            }}
            disableElevation
          >
            Test
          </LoadingButton>
        </span>
      </Tooltip>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "20px",
          paddingInline: 2,
          paddingBottom: 4,
          height: "76vh",
          overflowY: "auto",
        }}
      >
        <Box
          container
          item
          xs={12}
          display="flex"
          flexDirection="column"
          sx={{ width: "100%" }}
        >
          {/* First Accordion with AssistantForm as content */}
          <CustomAccordion
            panel="panel1"
            expanded={expanded}
            handleChange={handleChange}
            summary="General settings"
            content={
              <GeneralSettings
                assistant={assistant}
                handleChange={handleFormChange}
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
              />
            }
          />
          <Divider />
          <CustomAccordion
            panel="panel2"
            expanded={expanded}
            handleChange={handleChange}
            summary="Speech settings"
            content={
              <SpeechSettings
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
              />
            }
          />
          <Divider />
          <CustomAccordion
            panel="panel3"
            expanded={expanded}
            handleChange={handleChange}
            summary="Call settings"
            content={
              <CallSettings
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
              />
            }
          />
          <Divider />
          <CustomAccordion
            panel="panel4"
            expanded={expanded}
            handleChange={handleChange}
            summary="Functions"
            content={
              <AssistantFunctions
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
                handleFetchAllActionData={handleFetchAllActionData}
                setCallAllActionsApi={setCallAllActionsApi}
                handleSaveChanges={handleSaveChanges}
              />
            }
          />
          <Divider />
          <CustomAccordion
            panel="panel6"
            expanded={expanded}
            handleChange={handleChange}
            summary="Knowledge Base"
            content={
              <KnowledgeBaseAssistant
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
              />
            }
          />
        </Box>
      </Card>
    </>
  );
}
