import { Box, Grid, Typography, Select, MenuItem } from "@mui/material";
import React, { useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";

const KnowledgeBaseAssistant = ({
  assistant,
  handleChange,
  handleChangeAssistantChangedData,
}) => {
  const [selectedKB, setSelectedKB] = useState(""); 
  const knowledgeBaseList = useSelector(
    (state) => state.knowledgeBasesDetails.knowledgeBaseList
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Knowledge Bases
              <Box mb={0.3}>
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        <strong>Knowledge Base (KB):</strong> The selected
                        Knowledge Base will be available for searches and
                        responses, ensuring data from this KB is used actively
                        in the application.
                      </p>
                    </>
                  }
                />
              </Box>
            </Typography>
            <Typography variant="caption">
              A Knowledge Base (KB) stores structured information for queries.
              Enabling it makes its data usable; disabling excludes it until
              re-enabled. Only one KB can be active at a time.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Select Knowledge Base:
          </Typography>
          <Select
                  fullWidth
                  value={selectedKB}
                  onChange={(event) => {
                    setSelectedKB(event?.target?.value);
                    handleChangeAssistantChangedData({ knowledgeBases: [event?.target?.value?._id] }); 
                  }}
                  displayEmpty
                  size="small"
                >
            <MenuItem value="" disabled>
              Choose a Knowledge Base
            </MenuItem>
            {knowledgeBaseList
              ?.filter((kb) => kb.isTrained === true) 
              .map((kb) => (
                <MenuItem key={kb._id} value={kb}>
                  {kb.name}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeBaseAssistant;
