import { lazy, Suspense, useMemo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import * as _ from "lodash";
import posthog from "posthog-js";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BG_LIGHT from "../data/assets/img/bgimage.png";
import BG_DARK from "../data/assets/img/bgDark.png";
import themeConfig from "../data/theme";
import {
  clearDataFromLocalStorage,
  getDataFromLocalStorage,
  getDeviceData,
  log,
  setDataInLocalStorage,
} from "../data/configs/utils";
import { LOCAL_STORAGE, ROUTE_PATH } from "../data/configs/constants";
import { updatePageState } from "../data/store/pageSlice";
import { appSumoAssist, getUserProfile } from "../data/store/authSlice";
import { onUserLogin, setLocation } from "../data/configs/clevertap";

import SpinnerLoader from "../components/SpinnerLoader";
import ErrorBoundary from "../components/ErrorBoundary";
import GlobalInformation from "../components/GlobalInformation";
import GoogleAuthentication from "../components/GoogleAuthentication";
import VerifyEmail from "../components/VerifyEmail";
import Ticker from "../components/Ticker";
import AssistantSetup from "../components/AssistantSetup";
import { Box, Button, Container } from "@mui/material";
import CallDetailTranscript from "../components/CallDetailTranscript";
import AppSumoSignup from "./AuthContainer/AppSumoSignup";
import axios from "axios";
import HubSpotVerification from "../components/HubSpotVerification";
import PostHogPageviewTracker from "../PostHogPageviewTracker";
import GoHighLevelVerification from "../components/GoHighLevelVerification";
import { verifyGoHighLevel } from "../data/store/integrationSlice";
import DevelopersApi from "./PageContainer/DevelopersApi";
import AssistantConfig from "../components/AssistantConfig";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PricingRedirectButton from "../components/PricingRedirectButton";
const PageNotFound = lazy(() => import("../components/PageNotFound"));

const AuthContainer = lazy(() => import("./AuthContainer"));
const Login = lazy(() => import("./AuthContainer/Login"));
const Signup = lazy(() => import("./AuthContainer/Signup"));
const ForgotPassword = lazy(() => import("./AuthContainer/ForgotPassword"));
const ResetPassword = lazy(() => import("./AuthContainer/ResetPassword"));
const SignupPassword = lazy(() => import("./AuthContainer/SignupPassword"));
const OnBoardingContainer = lazy(() => import("./OnBoardingContainer"));
const Welcome = lazy(() => import("./OnBoardingContainer/Welcome"));
const MicroSurvey = lazy(() => import("./OnBoardingContainer/MicroSurvey"));
const CreateFirstAssistant = lazy(() =>
  import("./OnBoardingContainer/CreateFirstAssistant")
);

const PageContainer = lazy(() => import("./PageContainer"));
const PhoneNumbers = lazy(() => import("./PageContainer/PhoneNumbers"));
// const Settings = lazy(() => import("./PageContainer/Settings"))
const Campaigns = lazy(() => import("./PageContainer/Campaigns"));
const EditCampaign = lazy(() =>
  import("./PageContainer/Campaigns/EditCampaign")
);
const ContactList = lazy(() => import("./PageContainer/ContactList"));
const Assistants = lazy(() => import("./PageContainer/Assistants"));
const KnowledgeBases = lazy(() => import("./PageContainer/KnowledgeBases"))
const CallHistory = lazy(() => import("./PageContainer/CallHistory"));

const EditContact = lazy(() =>
  import("./PageContainer/ContactList/EditContact")
);
const Support = lazy(() => import("./PageContainer/Support"));
const Integrations = lazy(() => import("./PageContainer/Integrations"));
const Pricing = lazy(() => import("./PageContainer/Pricing"));
const Billing = lazy(() => import("./PageContainer/Billing"));
const ThankYou = lazy(() => import("../components/PaymentSuccess"));

const App = () => {
  const dispatch = useDispatch();
  const resizeTimerRef = useRef(null);
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const user = useSelector((state) => state.authDetails.user);
  const planName = useSelector(
    (state) => state.authDetails.user?.subscription?.planName
  );
  const email = useSelector((state) => state.authDetails?.user?.email);
  const userId = useSelector((state) => state.authDetails?.user?.userId);
  const posthogKey = process.env.REACT_APP_POSTHOG_KEY;

  if (posthogKey) {
    posthog.identify(email, { email: email, userId: userId });
  }

  useEffect(() => {
    const handleDeviceResizeChange = () => {
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current);
      resizeTimerRef.current = setTimeout(
        () =>
          dispatch(
            updatePageState({
              deviceData: getDeviceData(),
            })
          ),
        500
      );
    };
    window.addEventListener("resize", handleDeviceResizeChange);

    return () => {
      window.removeEventListener("resize", handleDeviceResizeChange);
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current);
    };
  }, [dispatch]);

  const goHighLevelVerificationRedirect = () => {
    const appSumoSearchParamsCode = getDataFromLocalStorage(
      LOCAL_STORAGE.GOHIGH_LEVEL_CODE
    );
    if (!_.isEmpty(appSumoSearchParamsCode))
      dispatch(verifyGoHighLevel({ ...appSumoSearchParamsCode }))
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            clearDataFromLocalStorage(LOCAL_STORAGE.GOHIGH_LEVEL_CODE);
          }
          enqueueSnackbar("Verification sent Successfully!", {
            variant: "success",
          });
          dispatch(getUserProfile())
            .unwrap()
            .then(() => {
              enqueueSnackbar("Integration Successful", { variant: "success" });
            })
            .catch((err) => log("ERROR-getUserProfile: ", err));
        })
        .catch((err) => log("ERROR-GoHighLevelVerification: ", err));
  };

  const appSumoAssistFunction = async () => {
    let baseUrl = process.env.REACT_APP_BASE_URL;
    let assistUrl = process.env.REACT_APP_APP_SUMO_ASSIST;
    const apiUrl = baseUrl + assistUrl;
    if (user?.userId) {
      const appSumoSearchParamsCode = getDataFromLocalStorage(
        LOCAL_STORAGE.APP_SUMO_CODE
      );
      const token = getDataFromLocalStorage(LOCAL_STORAGE.AUTH_TOKEN);
      if (
        !_.isEmpty(appSumoSearchParamsCode) &&
        planName &&
        planName === "Free"
      ) {
        try {
          const res = await axios.post(apiUrl, appSumoSearchParamsCode, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (res.status === 200) {
            clearDataFromLocalStorage(LOCAL_STORAGE.APP_SUMO_CODE);
          }
        } catch (e) {
          log("ERROR-Assist:", e.response);
          enqueueSnackbar(e.response.data.message, { variant: "error" });
          clearDataFromLocalStorage(LOCAL_STORAGE.APP_SUMO_CODE);
        }
      }
    }
  };

  useEffect(() => {
    const isNewUser = getDataFromLocalStorage(LOCAL_STORAGE.IS_NEW_USER);
    const authSearchParams = getDataFromLocalStorage(
      LOCAL_STORAGE.AUTH_SEARCH_PARAMS
    );
    const utmParams = getDataFromLocalStorage(LOCAL_STORAGE.UTM_PARAMS) || "";
    const utmParamsObj = Object.fromEntries(new URLSearchParams(utmParams));
    if (isNewUser && user?.userId) {
      const params = new URLSearchParams(authSearchParams);
      const paramsObj = Object.fromEntries(params);
      Object.assign(paramsObj, utmParamsObj);
      dispatch(getUserProfile({ ...paramsObj }))
        .then(() => {
          setDataInLocalStorage(LOCAL_STORAGE.IS_NEW_USER, false);
          setDataInLocalStorage(LOCAL_STORAGE.AUTH_SEARCH_PARAMS, "");
        })
        .then(() => appSumoAssistFunction())
        .then(() => goHighLevelVerificationRedirect());
    } else if (user?.userId)
      dispatch(getUserProfile({ ...utmParamsObj }))
        .then(() => appSumoAssistFunction())
        .then(() => goHighLevelVerificationRedirect());
  }, [dispatch, user?.userId]);

  useEffect(() => {
    const bgImageChange = () => {
      const rootElement = document.getElementById("saas-dashboard-voice-genie");
      if (rootElement) {
        switch (themeMode) {
          case "light":
            rootElement.style.background = `url(${BG_LIGHT}) no-repeat center/cover`;
            break;
          case "dark":
            rootElement.style.background = `url(${BG_DARK}) no-repeat center/cover`;
            break;
          default:
            rootElement.style.background = `url(${BG_LIGHT}) no-repeat center/cover`;
            break;
        }
      }
    };

    bgImageChange();
  }, [themeMode]);

  useEffect(() => {
    if (user?.userId && user?.email) {
      onUserLogin({
        Name: user?.nickname,
        Email: user?.email,
        Identity: user?.userId,
      });
      if (process.env.REACT_APP_IPINFO_URL) {
        fetch(process.env.REACT_APP_IPINFO_URL)
          .then((res) => res.json())
          .then((result) => {
            const location = result.loc.split(",");
            setLocation(location);
          })
          .catch((error) => {
            log("IpInfo Error: ", error);
          });
      }
    }
  }, [user?.nickname, user?.email, user?.userId]);

  let theme = useMemo(() => createTheme(themeConfig[themeMode]), [themeMode]);
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        style={{
          maxWidth: 400,
        }}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        action={(snackbarId) => (
          <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
        preventDuplicate
      />
      <Router>
        <PostHogPageviewTracker />
        <ErrorBoundary>
          <GlobalInformation />
        </ErrorBoundary>
        <Ticker />
        {/* <Container
          maxWidth="100%"
          sx={{
            backgroundColor: "#252525",
            color: "white",
            textAlign: "center",
            py: 0.5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          VoiceGenie Limited Time Black Friday Discount is live
          <PricingRedirectButton />
        </Container> */}
        <Routes>
          <Route
            path={ROUTE_PATH.VERIFY_GOOGLE_AUTH}
            element={<GoogleAuthentication />}
          />
          <Route path={ROUTE_PATH.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route
            element={
              <Suspense fallback={<SpinnerLoader brand />}>
                <AuthContainer />
              </Suspense>
            }
          >
            <Route
              path={ROUTE_PATH.LOGIN}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNUP}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Signup />
                </Suspense>
              }
            />
            {/* <Route
              path={ROUTE_PATH.APPSUMO_SIGNUP}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <AppSumoSignup />
                </Suspense>
              }
            /> */}

            <Route
              path={ROUTE_PATH.FORGOT_PASSWORD}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.RESET_PASSWORD}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <ResetPassword />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNUP_PASSWORD}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <SignupPassword />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={ROUTE_PATH.WELCOME}
            element={
              <Suspense fallback={<SpinnerLoader brand />}>
                <OnBoardingContainer />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Welcome />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.MICRO_SURVEY}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <MicroSurvey />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CREATE_FIRST_ASSISTANT}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <CreateFirstAssistant />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Box justifyContent="center" width="95vw" marginTop={12}>
                    <AssistantConfig />
                  </Box>
                </Suspense>
              }
            />
          </Route>
          <Route
            path={ROUTE_PATH.ASSISTANTS}
            element={
              <Suspense fallback={<SpinnerLoader />}>
                <PageContainer />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Assistants />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.EDIT_ASSISTANT}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  {/* <AssistantSetup /> */}
                  <AssistantConfig />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PHONE_NUMBERS}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <PhoneNumbers />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CONTACT_LIST}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <ContactList />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.EDIT_CONTACT}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <EditContact />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.KNOWLEDGE_BASES}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <KnowledgeBases />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CALL_HISTORY}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <CallHistory />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CALL_HISTORY_AND_CAMPAIGN_CALL_DETAIL}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <CallDetailTranscript />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CAMPAIGNS}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Campaigns />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.EDIT_CAMPAIGN}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <EditCampaign />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.HUBSPOT_VERIFICATION}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <HubSpotVerification />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.GOHIGHLEVEL_VERIFICATION}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <GoHighLevelVerification />
                </Suspense>
              }
            />
            {/* <Route
              path={ROUTE_PATH.SETTINGS}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Settings />
                </Suspense>
              }
            /> */}
            <Route
              path={ROUTE_PATH.SUPPORT}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Support />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.DEVELOPERSAPI}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <DevelopersApi />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.INTEGRATIONS}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Integrations />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.THANK_YOU}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <ThankYou />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.PRICING}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Pricing />
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.BILLING}
              element={
                <Suspense fallback={<SpinnerLoader />}>
                  <Billing />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <Suspense fallback={<SpinnerLoader />}>
                <PageNotFound />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
